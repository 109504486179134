<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :maskClosable="false"
      :width="1200"
      @ok="visible=false"
      @cancel="visible = false"
  >
    <div>
      <a-tabs default-active-key="base" v-model="activeKey" @change="onTabChanged">
        <a-tab-pane key="base" tab="基本信息" forceRender>
          <evaluation-base-info v-if="visible" ref="evaBaseInfo" @success="editSuccess"></evaluation-base-info>
        </a-tab-pane>
        <a-tab-pane key="factor" tab="结果因子" :disabled="mode=='new'" forceRender>
          <evaluation-result-factor ref="evaResultFactor"/>
        </a-tab-pane>
        <a-tab-pane key="topic" tab="评测题目" :disabled="mode=='new'" forceRender>
          <evaluation-topic-list ref="evaTopicList"/>
        </a-tab-pane>
      </a-tabs>

    </div>
    <div slot="footer">
      <div style="text-align: right">
        <a-button type="default" @click="visible=false">取消</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import EvaluationBaseInfo from "./EvaluationBaseInfo";
import EvaluationResultFactor from "./EvaluationResultFactor";
import EvaluationTopicList from "./EvaluationTopicList";


export default {
  name: "ModifyEvaluationModal",
  components: {EvaluationBaseInfo, EvaluationResultFactor, EvaluationTopicList},
  data() {
    return {
      mode: 'edit',
      evaId: 0,
      title: "",
      activeKey: 'base',
      visible: false,
      factorLoaded: false,
      topicLoaded: false
    };
  },
  created() {
  },
  methods: {
    showModal(record, mode) {
      this.visible = true;
      this.mode = mode || 'new';
      this.factorLoaded = false
      this.topicLoaded = false
      this.activeKey = 'base';
      if (this.mode == 'new') {
        this.title = "创建评测";
        this.evaId = 0;
      } else {
        this.evaId = record.id;
        this.title = "修改评测，ID：" + record.id;
      }
      this.$nextTick(() => {
        this.$refs.evaBaseInfo.init(record, this.mode)
      })
    },
    editSuccess() {
      this.visible = false;
      this.$emit('success')
    },
    onTabChanged(activeKey) {
      this.activeKey = activeKey;
      this.$nextTick(() => {
        if (activeKey == 'factor' && !this.factorLoaded)
          this.$refs.evaResultFactor.init(this.evaId);
        this.factorLoaded = true
      })
      if (activeKey == 'topic' && !this.topicLoaded) {
        this.$nextTick(() => {
          this.$refs.evaTopicList.init(this.evaId);
          this.topicLoaded = true
        })
      }
    }
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-modal-body {
  padding-top: 0;
}

.ant-input-number {
  width: 100% !important;
}
</style>
